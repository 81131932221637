import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { setColor, setFont } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { getAllRoomsByUserId, getRoomUsers } from './api/myRoomsController';
import { capitalizeString } from '../../../../utils/generalUtils';
import DataTable from '../../../../components/common/dataTable/DataTable';
import Typography from '../../../../components/common/text/Typography';
import { default as Button } from '../../../../components/common/button/Button';
import { ReactComponent as dustbin } from '../../../../assets/images/icons/dustbin.svg';
import { ReactComponent as add } from '../../../../assets/images/icons/add.svg';
import { ReactComponent as childView } from '../../../../assets/images/icons/childView.svg';
import { ReactComponent as userRemove } from '../../../../assets/images/icons/userRemove.svg';
import { ReactComponent as exitRoom } from '../../../../assets/images/icons/exitRoom.svg';
import Modal from '../../components/Modal';
import RemoveMeFromRoomModal from './components/RemoveMeFromRoomModal';
import AddUsersToMyRooms from './components/AddUsersToMyRooms';
import CreateMyRoom from './components/CreateMyRoom';
import DeleteRoomUserModal from './components/DeleteRoomUserModal';
import DeleteRoomModal from './components/DeleteRoomModal';

const MyRooms = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [roomsData, setRoomsData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [visibleRows, setVisibleRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalNumber, setTotalNumber] = useState(0);
    const [fullName, setFullName] = useState('');
    const [userRole, setUserRole] = useState('');
    const ROWS_PER_PAGE = 5;

    const openModal = (row) => {
        setSelectedRow(row);
        navigate(`/console/rooms/${row.roomId}`);
    };

    const renderRow = (row) => [row.roomId, row.roomName, row.userRoomRole, row.roomType];

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await getAllRoomsByUserId(user.userId);
            if (response.status === 200) {
                const { data } = response.data;
                setRoomsData(data);
                setTotalNumber(data.length);
                setFullName(data[0].userName || 'User');
                setUserRole(capitalizeString(data[0].roleName));
                setTotalPages(Math.ceil(data.length / ROWS_PER_PAGE));
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setVisibleRows(roomsData.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
    }, [currentPage, roomsData]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <MainCardTable>
                <TableRow>
                    <Title title={'MY ROOMS'} description={''} />
                </TableRow>
            </MainCardTable>
            <Body isLoading={isLoading}>
                {userRole !== '' && (
                    <>
                        <CardTable>
                            <TableRow>
                                <Typography variant="PinkText" margin={'0 0 1rem 0'}>
                                    USER INFO
                                </Typography>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <TitleHeading>ID:</TitleHeading> {user.userId}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TitleHeading>EMAIL:</TitleHeading> {user.email}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TitleHeading>DISPLAY NAME:</TitleHeading> {user.user}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TitleHeading>ROLE:</TitleHeading> {userRole}
                                </TableColumn>
                            </TableRow>
                        </CardTable>
                    </>
                )}

                {!isLoading && (
                    <>
                        <CardTable>
                            <TableRow>
                                <Typography variant="PinkText" margin={'0 0 0 0'}>
                                    {fullName}'S ROOMS ACCESS
                                </Typography>

                                <TableColumnButton>
                                    <Typography variant="ConsoleDescription" margin={'0 0 0 0'}>
                                        Total: {totalNumber} rooms
                                    </Typography>
                                </TableColumnButton>
                            </TableRow>

                            {userRole === 'Superadmin' && (
                                <TableRow>
                                    <TableColumnButton>
                                        <Typography variant="ConsoleDescription" margin={'0 0 0 0'}>
                                            As a superadmin you can view all the rooms.
                                        </Typography>
                                    </TableColumnButton>
                                </TableRow>
                            )}
                        </CardTable>
                    </>
                )}

                {visibleRows && (
                    <DataTable
                        headers={['ROOM ID', 'NAME', 'ROOM ROLE', 'TYPE']}
                        data={visibleRows}
                        renderRow={renderRow}
                        isModalOpen={isModalOpen}
                        openModal={openModal}
                        selectedRow={selectedRow}
                    />
                )}
            </Body>
            <Footer>
                <TableColumnLeft>Total Number of Rooms: {totalNumber}</TableColumnLeft>
                <TableColumnRight>
                    {visibleRows.length !== 0 && (
                        <Pagination>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <PaginationButton
                                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </PaginationButton>
                            <PaginationButton
                                onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </PaginationButton>
                        </Pagination>
                    )}
                </TableColumnRight>
            </Footer>
        </>
    );
};

export default MyRooms;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0 0;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
    font-size: 17px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText};
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled};
    }
`;

const TableColumnTop = styled.div`
    width: 100%;
    // flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    border-radius: 5px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TitleHeading = styled.span`
    color: ${setColor.accent4};
    font-weight: 700;
`;

const StyledDeleteIcon = styled(dustbin)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;

const StyledChildViewIcon = styled(childView)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;

const StyledUserRemoveIcon = styled(userRemove)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;

const StyledExitRoomIcon = styled(exitRoom)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;

const StyledAddIcon = styled(add)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;

const MainCardTable = styled.div`
    display: flex;
    flex-direction: column;
`;
