/* eslint-disable object-shorthand */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { fetchUserToken } from '../../redux/slices/users';
import './VagonStream.css';
import {
    changeURLByRoomId,
    exitFullScreen,
    enterFullScreen,
    isIOSDevice,
    extractParamValue,
} from '../../utils/generalUtils';
import SpotifyInterface from '../spotify/components/SpotifyControls';
import * as constants from '../../constants';
import { sendEventsLog, Logout } from '../../api/UserController';
import { resumePlayer } from '../spotify/api/SpotifyController';
import { fetchVanityLinkData } from '../../api/VanityLinkController';
import { startInterval, stopInterval, alterState, sendVagonMessage } from './helpers/utils';
import VanityFeatures from './components/VanityFeatures';
import { setToSessionStorage, getFromSessionStorage } from '../../hooks/useSessionStorage';
import Igt from '../igt/Igt';
import LionGamingCasino from '../lionGaming/LionGamingCasino';
import ArcadeGame from '../arcadeGames/ArcadeGame';
import { getRoomSettingsById } from '../../api/RoomsController';
import CasinoLight from '../casinoLight/CasinoLight';
import Streaming from '../streaming/Streaming';
import Guestbook from '../guestbook/Guestbook';
import SpotifyControls from '../spotify/components/SpotifyControlsV2';

const VagonStreamIntraverse = (props) => {
    const teddySwimRoomId = '992ee1dc-474b-46ed-9788-3b35739880f5';
    const isIOS = isIOSDevice();
    const elementRef = useRef(null);
    const intervalIdRef = useRef(null);
    const spotifyPlaying = useRef(false);

    const [vanityLinkInfo, setVanityLinkInfo] = useState(JSON.parse(localStorage.getItem('vanityLinkInfo')) || {});
    const [roomSettings, setRoomSettings] = useState(JSON.parse(localStorage.getItem('roomSettings')) || {});

    const [showCornerButtons, setShowCornerButtons] = useState(true);
    const [landscape, setLandscape] = useState(false);
    const [fullScreen, setFullScreen] = useState(JSON.parse(getFromSessionStorage('fullscreen') || 'false'));
    const [openBroadcastModal, setOpenBroadcastModal] = useState(false);
    const [playingMusic, setPlayingMusic] = useState(false);
    const [openSpotify, setOpenSpotify] = useState(false);

    const [isStreaming, setIsStreaming] = useState(false);
    const [igtSlot, setIgtSlot] = useState(null);
    const [lionGamingSlot, setLionGamingSlot] = useState(null);
    const [casinoLightSlot, setCasinoLightSlot] = useState(null);
    const [arcadeGame, setArcadeGame] = useState(null);

    // These features are WIP, need to be made dynamically configurable
    const [teddyNoteOpen, setTeddyNoteOpen] = useState(false);
    const [openTeddySelfiePopup, setOpenTeddySelfiePopup] = useState(false);
    const [openContestModalState, setOpenContestModalState] = useState(false);
    const [isGuestbookOpen, setIsGuestbookOpen] = useState(false);

    const setVanityLinkData = async (roomId) => {
        const vanityLink = await changeURLByRoomId(roomId);
        if (vanityLink) {
            const { data } = await fetchVanityLinkData(vanityLink);
            setVanityLinkInfo((value) => ({ ...value, ...data }));
            localStorage.setItem('vanityLinkInfo', JSON.stringify(data));
        }
    };

    const setVanityRoomSettings = async (roomId) => {
        const { data } = await getRoomSettingsById(roomId);

        if (data) {
            setRoomSettings((value) => ({ ...value, ...data }));
            localStorage.setItem('roomSettings', JSON.stringify(data));

            if (data?.spotifyTrack) {
                startSpotifyPlayer(data?.spotifyTrack);
            }
        }
    };

    const setIgtSlotData = async (data) => {
        setIgtSlot(data);
    };

    const setLionGamingSlotData = async (data) => {
        setLionGamingSlot(data);
    };
    const setCasinoLightSlotData = async (data) => {
        setCasinoLightSlot(data);
    };
    const setArcadeGameData = async (data) => {
        setArcadeGame(data);
    };

    const removeVanityLinkData = () => {
        if (spotifyPlaying.current) {
            props.spotifyPlayer?.pause();
            alterState(setPlayingMusic);
            spotifyPlaying.current = false;
        }
        localStorage.setItem('vanityLinkInfo', null);
        localStorage.setItem('roomSettings', null);
        setVanityLinkInfo({});
        setRoomSettings({});
        setOpenSpotify(false);
    };

    const startSpotifyPlayer = useCallback(async (track) => {
        const type = constants.ALBUM;
        const spotifyToken = getFromSessionStorage('spotifyAccessToken');
        if (spotifyToken) {
            try {
                spotifyPlaying.current = true;
                const result = await resumePlayer(spotifyToken, track, type);
                if (result === 200) {
                    alterState(setPlayingMusic);
                }
            } catch {
                spotifyPlaying.current = false;
            }
        }
        setIsStreaming(false);
    }, []);

    const messageActions = {
        [constants.OPEN_BROADCAST_MODAL]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setOpenBroadcastModal);
        },
        [constants.OPEN_VIDEO_BROADCAST]: () => alterState(setIsStreaming),
        [constants.END_VIDEO_BROADCAST]: () => alterState(setIsStreaming),
        [constants.OPEN_TEDDY_SELFIE]: () => alterState(setOpenTeddySelfiePopup),
        [constants.OPEN_SPOTIFY]: () => alterState(setOpenSpotify),
        [constants.PDF_OPEN]: () => alterState(setShowCornerButtons),
        [constants.PDF_CLOSE]: () => alterState(setShowCornerButtons),
        [constants.OPEN_TEDDY_CONTEST]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setOpenContestModalState);
        },
        [constants.OPEN_TEDDY_NOTE]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setTeddyNoteOpen);
        },
        [constants.FULL_SCREEN]: () => {
            alterState(setFullScreen);
        },
        [constants.SETUPCARD]: () => {
            window.open('/wallet?source=web_unity');
        },
        [constants.PAUSE_BACKGROUND_MUSIC]: () => {
            const audio = document.getElementById('audio');
            audio.pause();
        },
        [constants.PLAY_BACKGROUND_MUSIC]: () => {
            const audio = document.getElementById('audio');
            audio.play();
        },
        [constants.ENTERING_NAV_MENU]: () => {
            const audio = document.getElementById('audio');
            audio.stop();
        },
    };

    // This function is called when the script is loaded, it is used to listen to the messages sent from the application
    // States can not be updated within the function, so we are using alterState function to update the state
    const loadedScript = () => {
        startInterval(intervalIdRef);

        window.Vagon.onConnected(() => {
            setTimeout(() => {
                const audio = document.getElementById('audio');
                audio.pause();
            }, 12000);
        });

        window.Vagon.onApplicationMessage(async (evt) => {
            const message = evt.message.toString();
            console.log('Vagon message: ', message);

            if (messageActions[message]) {
                messageActions[message]();
            } else if (message.includes(constants.ROOM_UNLOADED)) {
                removeVanityLinkData();
            } else if (message.includes(constants.NEW_ROOM_LOADING)) {
                const roomId = message.split(':');
                if (roomId[1]) {
                    setVanityLinkData(roomId[1]);
                    setVanityRoomSettings(roomId[1]);
                }
            } else if (message.includes(constants.SLOT)) {
                setIgtSlotData(message);
            } else if (message.includes(constants.LIONGAME)) {
                setLionGamingSlotData(message);
            } else if (message.includes(constants.CASINOLIGHT)) {
                setCasinoLightSlotData(message);
            } else if (message.includes(constants.OPEN_ARCADE_GAME)) {
                setArcadeGameData(message);
            } else if (message.includes(constants.LOGOUT)) {
                await Logout();
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
            } else if (message.includes(constants.OPEN_GUESTBOOK)) {
                setIsGuestbookOpen(true);
            }
            const paramStart = message.indexOf('&clientSessionId=');
            if (paramStart !== -1) {
                const clientSessionId = extractParamValue(message, 'clientSessionId');
                const userToken = extractParamValue(message, 'token');
                const envunity = extractParamValue(message, 'envunity');
                const userId = extractParamValue(message, 'displayName');

                if (clientSessionId !== null && userToken !== null && envunity !== null && userId !== null) {
                    setToSessionStorage('clientSessionId', clientSessionId);
                    setToSessionStorage('envUnity', envunity);
                    await sendEventsLog(
                        userToken,
                        'igt',
                        userId,
                        constants.LOG_EVENTS_LISTENER_DATA,
                        'info',
                        { generatedSlotUrl: window.location.href, message },
                        clientSessionId,
                        envunity
                    );
                }
            }
        });
    };

    const updateOrientation = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        const aspectRatio = width / height;
        const iframeElement = document.getElementById('vagonFrame');

        // second check to ensure aspect Ratio is below 1
        if (aspectRatio < 1) {
            iframeElement.classList.add('force-landscape');
            iframeElement.style.removeProperty('width');
            iframeElement.style.removeProperty('height');
            setLandscape(true);
        } else {
            iframeElement.classList.remove('force-landscape');
            iframeElement.style.width = '100vw';
            iframeElement.style.height = '100vh';
            setLandscape(false);
        }
        if (isIOS) iframeElement.style.padding = '10px';
    };

    useEffect(() => {
        if (fullScreen === true || fullScreen === 'true') {
            enterFullScreen(elementRef);
        } else if (
            (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullScreenElement) &&
            !fullScreen
        ) {
            setToSessionStorage('fullscreen', false);
            exitFullScreen();
        }
        updateOrientation();
    }, [fullScreen]);

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://app.vagon.io/vagonsdk.js');
        script.addEventListener('load', () => {
            loadedScript();
        });
        head.appendChild(script);

        window.addEventListener('resize', updateOrientation);
        return () => {
            window.removeEventListener('resize', updateOrientation);
        };
    }, []);

    useEffect(() => {
        if (openBroadcastModal || teddyNoteOpen || openContestModalState || openTeddySelfiePopup || isGuestbookOpen) {
            stopInterval(intervalIdRef);
        } else {
            startInterval(intervalIdRef);
        }
    }, [openBroadcastModal, teddyNoteOpen, openContestModalState, openTeddySelfiePopup, isGuestbookOpen]);

    return (
        <div
            ref={elementRef}
            className="iframe-container"
            style={{ width: '100vw', height: '100vh', background: 'black', zIndex: 100 }}
        >
            {igtSlot && <Igt data={igtSlot} setIgtSlot={setIgtSlot} />}
            {lionGamingSlot && <LionGamingCasino data={lionGamingSlot} setLionGamingSlot={setLionGamingSlot} />}
            {arcadeGame && <ArcadeGame onClose={() => setArcadeGame(null)} />}
            {casinoLightSlot && <CasinoLight data={casinoLightSlot} setCasinoLightSlot={setCasinoLightSlot} />}
            {isStreaming && <Streaming streamId={roomSettings.broadcastId} />}
            {isGuestbookOpen && <Guestbook onClose={() => setIsGuestbookOpen(false)} />}
            <VanityFeatures
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                vanityLinkInfo={vanityLinkInfo}
                openBroadcastModal={openBroadcastModal}
                setOpenBroadcastModal={setOpenBroadcastModal}
                showCornerButtons={showCornerButtons}
                landscape={landscape}
            />

            {roomSettings?.roomId === teddySwimRoomId ? (
                <SpotifyInterface
                    player={props?.spotifyPlayer}
                    setFullScreen={setFullScreen}
                    landscape={landscape}
                    teddyNoteOpen={teddyNoteOpen}
                    setTeddyNoteOpen={setTeddyNoteOpen}
                    openTeddySelfiePopup={openTeddySelfiePopup}
                    setOpenTeddySelfiePopup={setOpenTeddySelfiePopup}
                    playingMusic={playingMusic}
                    openContestModalState={openContestModalState}
                    setOpenContestModalState={setOpenContestModalState}
                    openSpotify={openSpotify}
                    setOpenSpotify={setOpenSpotify}
                    spotifyTrack={roomSettings?.spotifyTrack || null}
                    startSpotifyPlayer={startSpotifyPlayer}
                />
            ) : (
                <SpotifyControls setOpenSpotify={setOpenSpotify} openSpotify={openSpotify} player={props?.spotifyPlayer} />
            )}

            <iframe
                id="vagonFrame"
                title="Vagon Stream Content"
                allow="microphone *; clipboard-read *; clipboard-write *; encrypted-media *;"
                style={{ width: '100vw', height: '100vh', border: 0, zIndex: 100 }}
                src={props.streamUrl}
                sandbox="allow-pointer-lock allow-scripts allow-forms allow-same-origin allow-popups"
            />
        </div>
    );
};

VagonStreamIntraverse.propTypes = {
    streamUrl: PropTypes.string.isRequired,
    spotifyPlayer: PropTypes.object,
};

export default VagonStreamIntraverse;
