import { API_GET, API_POST, API_PUT } from '../../../config/axiosConfig';

export async function getAllNotes(roomId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/notes/${roomId}`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.notes;
        }
        return [];
    } catch (err) {
        console.log('Error fetching data:', err);
        return [];
    }
}

export async function getRoomUsers(roomId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.roomUsers;
        }
        return [];
    } catch (err) {
        console.log('err', err);
        return [];
    }
}

export async function getStickerFile(fileId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/files/${fileId}`;
        return await API_GET(url);
    } catch (err) {
        console.log('err', err);
        return null;
    }
}

export async function addNote(roomId, noteMessage, color, sticker, font, isAnonymous, useEmailCreator) {
    try {
        const redirectUrl = `${process.env.REACT_APP_WEB_URL}/guestbook`;
        let fontStyle = '';
        if (font === 'Times New Roman, serif') {
            fontStyle = 'Serif';
        } else if (font === 'Barlow, sans serif') {
            fontStyle = 'Sans Serif';
        } else if (font === 'Kalam, cursive') {
            fontStyle = 'Hand Writing';
        } else if (font === 'Cormorant Upright') {
            fontStyle = 'Cute';
        } else if (font === 'Satisfy') {
            fontStyle = 'Cursive';
        } else if (font === 'Sniglet') {
            fontStyle = 'Bubbly';
        } else if (font === 'Courier Prime') {
            fontStyle = 'Typewriter';
        } else if (font === 'Anta') {
            fontStyle = 'Futuristic';
        } else if (font === 'Pixelify Sans') {
            fontStyle = 'Pixel';
        } else {
            fontStyle = 'Serif';
        }

        const toSendData = {
            message: noteMessage,
            color,
            noteType: useEmailCreator ? 'email' : 'directmessage',
            sticker: sticker === 'none' ? '' : sticker,
            fontStyle,
            isAnonymous,
            redirectUrl,
        };
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/notes/${roomId}`;
        return await API_POST(url, toSendData);
    } catch (err) {
        console.log('err', err);
        return [];
    }
}

export async function updateNoteReaction(noteId, reaction, likesCount, dislikesCount) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/notes/reaction/${reaction}/${noteId}`;
        return await API_PUT(url, { likesCount, dislikesCount });
    } catch (err) {
        console.log('err', err);
        return [];
    }
}

export async function updateNote(noteIds, roomId, action) {
    try {
        const toSendData = {
            noteIds,
        };
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/notes/action/${action}/${roomId}`;
        return await API_PUT(url, toSendData);
    } catch (err) {
        console.log('err', err);
        return [];
    }
}

export async function checkProfanityMessage(message) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/notes/profanity-filter`;
        const data = {
            message,
        };
        return await API_POST(url, data);
    } catch (error) {
        console.error('error:', error);
        return error;      
    }
}