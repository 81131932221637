import { getFromSessionStorage } from '../../../hooks/useSessionStorage';
import * as spotifyConstants from '../../../constants';
import { API_GET, API_POST } from '../../../config/axiosConfig';

export async function handleSpotifyAuth(redirectUrl) {
    try {
        window.location.href = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/login/${encodeURIComponent(
            redirectUrl
        )}`;
    } catch (error) {
        console.error('Error during Spotify authentication:', error);
    }
}

export async function getSpotifyToken(code, urlParams, redirectUrl) {
    const token = null;

    if (code && urlParams) {
        // If the code is present, send a request to the backend to fetch the token
        try {
            const url = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/callback/${code}/${encodeURIComponent(
                redirectUrl
            )}`;

            const response = await API_GET(url);

            if (response.status === 200) {
                const data = await response.data;

                // Remove the code and state parameters from the URL
                urlParams.delete('code');
                urlParams.delete('state');
                const newUrl = `${window.location.origin}${window.location.pathname}`;
                window.history.replaceState({}, '', newUrl);

                return data;
            }
            console.error('Failed to fetch the token from the backend');
            return token;
        } catch (error) {
            console.error('Error during token fetch:', error);
            return token;
        }
    } else {
        return token;
    }
}

// eslint-disable-next-line camelcase
export async function transferPlayback(deviceId, token) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/transferPlayback/${deviceId}/${token}`;
        const response = await API_GET(url);
        return response;
    } catch (error) {
        console.error('Error during transfer playback:', error);
    }
}

export async function resumePlayer(token, contextURI, type) {
    try {
        const data = {
            accessToken: token,
            contextURI,
            type,
        };

        const response = await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/resumePlayback`, data);

        if (response.status === 200) {
            return response.status;
        }

        return null;
    } catch (error) {
        console.error('Error during resume player:', error);
        return null;
    }
}

export async function setRepeat(state, deviceId, token) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/setRepeat/${state}/${deviceId}/${token}`;
        const response = await API_GET(url);

        return response;
    } catch (error) {
        console.error('Error during resume player:', error);
    }
}

export async function setRepeatV2(token, state) {
    try {
        const data = {
            accessToken: token,
            state,
        };

        const response = await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/loop`, data);

        if (response.status === 200) {
            return response.status;
        }

        return null;
    } catch (error) {
        console.error('Error during loop player:', error);
        return null;
    }
}

export async function shufflePlayer(token, state) {
    try {
        const data = {
            accessToken: token,
            state,
        };

        const response = await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/spotify/shuffle`, data);

        if (response.status === 200) {
            return response.status;
        }

        return null;
    } catch (error) {
        console.error('Error during shuffle player:', error);
        return null;
    }
}

export async function getProfile(token) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/profile/${token}`;
        const response = await API_GET(url);
        if (response.status === 200) {
            const data = await response.data;
            return data;
        }
        return null;
    } catch (error) {
        console.error('Error during resume player:', error);
    }
}

export async function getSpotifyRefreshToken(refreshToken) {
    const token = null;

    if (refreshToken) {
        // If the code is present, send a request to the backend to fetch the token
        try {
            const url = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/refreshToken/${refreshToken}`;
            const response = await API_GET(url);

            if (response.status === 200) {
                const data = await response.data;

                return data.accessToken;
            }
            console.error('Failed to fetch the token from the backend');
            return token;
        } catch (error) {
            console.error('Error during token fetch:', error);
            return token;
        }
    } else {
        return token;
    }
}

export async function refreshUserToken(userId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users/auth-session`;
        const response = await API_POST(url, { userId });

        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error('Error during refresh token:', error);
        return null;
    }
}

export async function signUpUser(displayName, email) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users/login`;
        const data = { displayName, email };

        const response = await API_POST(url, data);

        if (response.status === 200) {
            const data = await response.data;
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during singn up:', error);
        return null;
    }
}

export const logStreaming = async (song, uri) => {
    const userId = getFromSessionStorage('userId');
    const userSession = getFromSessionStorage('userToken');
    if (!userId) {
        return;
    }
    const url = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/misc/teddys-lounge-spotify-tracking`;
    const data = {
        analyticsData: {
            trackId: uri,
            sessionId: userSession,
            userId,
            datetimeStarted: new Date(),
            song,
            durationInSeconds: spotifyConstants.STREAM_SECONDS,
        },
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userSession}`,
        },
    };

    const response = await API_POST(url, data, config);
};

export async function sendTeddyNoteToDb(name, message, userToken) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users/action-log`;
        const data = {
            analyticsData: {
                name,
                message,
            },
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        };
        const response = await API_POST(url, data, config);
        if (response.status === 200) {
            return response.status;
        }
    } catch (error) {
        console.error('Error sending message:', error);
        return null;
    }
}
