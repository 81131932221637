import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CustomTooltip } from '../../components/common/tooltip/CusomTooltip';
import purpleX from '../../assets/images/buttonIcons/purpleX.png';
import arkadium from './arcade-icons/arkadium.png';
import gameDefault from './arcade-icons/game-default.png';
import ExitContainer from './arcade-icons/ExitContainer.png';
import ExitContainer1 from './arcade-icons/ExitContainer1.png';
import ExitTag from './arcade-icons/ExitTag.png';
import FixedButton from './arcade-icons/FixedButton.png';
import webrcade from './arcade-icons/webrcade.png';
import { sendVagonMessage } from '../vagon/helpers/utils';
import * as constants from '../../constants';
import Modal from '../../pages/console/components/Modal';
import Typography from '../../components/common/text/Typography';
import Button from '../../components/common/button/Button';
import { setFont } from '../../assets/styles/styles';

const ArcadeGame = ({ onClose }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const modalMessage = useRef();
    const [selectedGameUrl, setSelectedGameUrl] = useState(null);

    const games = [
        {
            id: 1,
            name: 'Free Games',
            url: 'https://freegames.org/',
            icon: arkadium,
            tooltipText:
                'The BAFTA nominated free games website. Play online Mahjong, Bubble Shooter, Solitaire, Unfold, Match Drop and so much more.  Play now instantly!',
        },
        {
            id: 2,
            name: 'Skribbl.io!',
            url: 'https://skribbl.io/',
            icon: gameDefault,
            tooltipText: 'skribbl - Free Multiplayer Drawing & Guessing Game!',
        },
        {
            id: 3,
            name: 'Roulette',
            url: 'https://www.247roulette.org',
            icon: gameDefault,
            tooltipText:
                'Play the best free casino-style Roulette with no sign-in required! Enjoy instant, hassle-free fun online now!',
        },
        {
            id: 4,
            name: 'Blackjack',
            url: 'https://freegames.org/blackjack',
            icon: gameDefault,
            tooltipText: 'Free Blackjack - Play for pretend chips in this free online blackjack game.',
        },
        {
            id: 5,
            name: 'FreePoker',
            url: 'https://www.247freepoker.com',
            icon: gameDefault,
            tooltipText:
                'Free poker - free online poker games. 247 Free Poker has free online poker, jacks or better, tens or better, deuces wild, joker poker and many other poker games that you can play online for free or download.',
        },
        {
            id: 6,
            name: 'Webrcade',
            url: 'https://play.webrcade.com/',
            icon: webrcade,
            tooltipText: 'Introducing WebRcade — Play Your Retro Games via the Cloud!',
        },
    ];

    const updateModal = () => {
        setModalOpen((prev) => !prev);
    };

    useEffect(() => {
        async function handleMessage(event) {
            if (event.data.messageType === 'closeGame') {
                modalMessage.current = event.data?.messageData?.message || 'An error occurred. Please try again later.';
                updateModal();
            }
        }

        window.addEventListener('message', handleMessage);

        // Cleanup function
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                children={
                    <>
                        <Typography variant="pWhite" margin={'30px 0'}>
                            {modalMessage.current}
                        </Typography>
                        <Button
                            margin={'15px 0px 0px 0px'}
                            label="Okay"
                            height={'30px'}
                            variant="OutlinedButton"
                            onClick={() => {
                                // sendCloseEventLog();
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                modalMessage.current = false;
                            }}
                            buttonWidth="100%"
                            fontSize="20px"
                        />
                    </>
                }
                closeButton={false}
            />
            {selectedGameUrl ? (
                <>
                    <CustomTooltip placement="bottom" title={'Click to return to Intraverse!'}>
                        <ExitContainerDiv
                            type="button"
                            onClick={() => {
                                // sendCloseEventLog();
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                setSelectedGameUrl(null);
                            }}
                        >
                            <ExitImage src={ExitTag} alt="ExitTag" />
                        </ExitContainerDiv>
                    </CustomTooltip>
                    <iframe
                        title="Arcade Gaming Iframe Content"
                        id="splash"
                        // scrolling="no"
                        allow="autoplay"
                        style={{
                            position: 'absolute',
                            overflow: 'auto',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            padding: 0,
                            border: 0,
                            zIndex: 9998,
                            top: 0,
                            left: 0,
                        }}
                        src={selectedGameUrl}
                    />
                </>
            ) : (
                <GameMenuModal>
                    <ArcadeHeading> Arcade</ArcadeHeading>
                    {/*  make this dynamic based on title */}
                    <Typography style={{ textAlign: 'center', fontSize: '22px', color: '#FBFCFF' }} variant={'pWhite'}>
                        Choose a game library to start playing from thousands of options!
                        {/*  make this dynamic based on subtitle */}
                    </Typography>
                    <Grid itemCount={games.length}>
                        {games.map((game, index) => (
                            <CustomTooltip key={index} placement="bottom" title={game.tooltipText}>
                                <GameIcon onClick={() => setSelectedGameUrl(game.url)}>
                                    <img
                                        height={70}
                                        width={70}
                                        style={{ justifyContent: 'center' }}
                                        src={game.icon}
                                        alt="gameIcon"
                                    />
                                    <p>{game.name}</p>
                                </GameIcon>
                            </CustomTooltip>
                        ))}
                    </Grid>
                    <CloseButton
                        type="button"
                        onClick={() => {
                            // sendCloseEventLog();
                            sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                            setSelectedGameUrl(null);
                            onClose();
                        }}
                    >
                        <StyledImage style={{ height: '35px' }} src={purpleX} alt="Close" />
                    </CloseButton>
                </GameMenuModal>
            )}
        </div>
    );
};

export default ArcadeGame;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 1rem;
    justify-items: center;

    @media (max-width: 920px) {
        grid-template-rows: repeat(auto-fit, minmax(80px, 1fr));
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(2, auto);
        gap: 10px;
        margin-top: 0.5rem;
    }

    ${({ itemCount }) =>
        itemCount < 4 &&
        `
        grid-template-columns: repeat(${itemCount}, 1fr);
    `}
`;

const ArcadeHeading = styled.h2`
    font-family: 'Signika Negative';
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(180deg, #beaeff 0%, rgba(190, 174, 255, 0.6) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const GameMenuModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(253deg, rgba(11, 11, 44, 0.9) 4.16%, rgba(33, 13, 53, 0.9) 100.79%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    padding-top: 1rem;

    @media (max-width: 920px) {
        padding-top: 3rem;
    }
`;

const GameIcon = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 7.667px;
    border: 2px solid var(--Dark-Grey-Stroke, #3d364f);
    background: #23173e;

    & p {
        color: #fbfcff;
        font-family: ${setFont.barlow};
        font-weight: 500;
        font-size: 18px;
        margin-top: 0.7rem;
        letter-spacing: normal;
    }

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 920px) {
        width: 150px;
        height: 150px;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    z-index: 9999;
    top: 15px;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 960px) {
        right: 5px;
    }
`;

const ExitContainerDiv = styled.button`
    position: absolute;
    z-index: 9999;
    top: 10px;
    right: 0px;
    height: 120px;
    width: 320px;
    border: none;
    background: url(${ExitContainer1}) no-repeat;
    background-size: cover;
    cursor: pointer;
    display: flex;
    transform: scale(0.7);
    transform-origin: top right;
    align-items: center;
    justify-content: center;

    @media (max-width: 960px) {
        transform: scale(0.6);
    }
`;

const ExitImage = styled.img`
    position: relative;
    height: 50px;
    width: auto;
    margin-right: 3rem;
`;
