import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { fetchUserId } from '../../redux/slices/users';
import purpleX from '../../assets/images/buttonIcons/purpleX.png';
import { sendVagonMessage } from '../vagon/helpers/utils';
import * as constants from '../../constants';
import Modal from '../../pages/console/components/Modal';
import Typography from '../../components/common/text/Typography';
import Button from '../../components/common/button/Button';
import { getRoomGames } from '../../pages/console/admin/rooms/api/roomController';
import { sendEventsLogCustomBody } from '../../api/UserController';

const CasinoLight = (props) => {
    const roomSettings = JSON.parse(localStorage.getItem('roomSettings')) || {};
    const [modalOpen, setModalOpen] = React.useState(false);
    const [url, setUrl] = React.useState(null);
    const modalMessage = useRef();
    const userId = useSelector(fetchUserId);

    const {
        data: games,
        error: gameErr,
        isLoading: gameLdng,
    } = useQuery(['games', roomSettings?.roomId], () => getRoomGames(roomSettings?.roomId), {
        enabled: !!roomSettings?.roomId,
        ...constants.CACHE_QUERY_OPTIONS(),
    });

    const fetchUrl = async () => {
        try {
            const params = props.data.split('&').reduce((acc, param) => {
                const [key, value] = param.split('=');
                acc[key] = value;
                return acc;
            }, {});

            const casinoLight = params.casinolight;
            if (games?.length !== 0) {
                games.forEach((element) => {
                    if (String(element.slotNumber) === casinoLight) {
                        setUrl(element.url);
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching url:', error);
            setUrl('https://jackpot.onelink.me/1833068633/2ti29w3k');
        }
    };

    const submitAchievement = async () => {
        try {
            const achievementData = {
                source: 'web',
                buildId: '1',
                userId,
                ownerId: null,
                roomId: null,
                serverId: '1',
                eventTs: new Date().toISOString(),
                eventName: 'achievement',
                eventType: 'True Gamer',
                eventJson: {
                    count: 1,
                },
                envApi: process.env.REACT_APP_STREAM_API_ENV,
                url: process.env.REACT_APP_WEB_URL,
            };
            const trueGamerAchievement = await sendEventsLogCustomBody(achievementData);

            if (trueGamerAchievement) {
                sendVagonMessage(constants.TRUE_GAMER_ACHIEVEMENT);
            }
        } catch (error) {
            console.error('Error setting achievement:', error);
        }
    };

    useEffect(() => {
        if (games) {
            fetchUrl();
            submitAchievement();
        }
    }, [props.data, games]);

    const updateModal = () => {
        setModalOpen((prev) => !prev);
    };

    useEffect(() => {
        async function handleMessage(event) {
            if (event.data.messageType === 'closeGame') {
                modalMessage.current = event.data?.messageData?.message || 'An error occurred. Please try again later.';
                updateModal();
            }
        }

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                children={
                    <>
                        <Typography variant="pWhite" margin={'30px 0'}>
                            {modalMessage.current}
                        </Typography>
                        <Button
                            margin={'15px 0px 0px 0px'}
                            label="Okay"
                            height={'30px'}
                            variant="OutlinedButton"
                            onClick={() => {
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                props.setCasinoLightSlot(null);
                                modalMessage.current = false;
                            }}
                            buttonWidth="100%"
                            fontSize="20px"
                        />
                    </>
                }
                closeButton={false}
            />
            {url && (
                <>
                    <iframe
                        title="iGaming Iframe Content"
                        id="splash"
                        scrolling="no"
                        allow="autoplay"
                        style={{
                            position: 'absolute',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            padding: 0,
                            border: 0,
                            zIndex: 9998,
                            top: 0,
                            left: 0,
                        }}
                        src={url}
                    />

                    <CloseButton
                        type="button"
                        onClick={() => {
                            sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                            props.setCasinoLightSlot(null);
                        }}
                    >
                        <StyledImage src={purpleX} alt="Close" />
                    </CloseButton>
                </>
            )}
        </div>
    );
};

export default CasinoLight;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    z-index: 9999;
    top: 45px;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 960px) {
        top: 40px;
        right: 15px;
    }
`;
