import { SUPERADMIN_ROLE, ADMIN_ROLE, GUEST_ROLE, USER_ROLE } from '../../constants';

export const fetchIsMerchant = (state) => state?.user?.isMerchant;
export const fetchUserName = (state) => state?.user?.user;
export const fetchUserToken = (state) => state.user.token;
export const fetchUserEmail = (state) => state.user.email;
export const fetchUserRole = (state) => state?.user?.role;
export const fetchUserId = (state) => state.user.userId;
export const fetchProfileIcon = (state) => state.user.profileIcon;
export const fetchTokenExpiration = (state) => state.user.expiration;
export const fetchIsEmailVerified = (state) => state?.user?.isEmailVerified;
export const isGuestRole = (state) => state?.user?.role === GUEST_ROLE;
export const isSuperAdminRole = (state) => state?.user?.role === SUPERADMIN_ROLE;
export const isAdminRole = (state) => state?.user?.role === ADMIN_ROLE;
export const isUserRole = (state) => state?.user?.role === USER_ROLE;
