import React from 'react';
import styled from 'styled-components';
import Hex from '../assets/icons/Hex.png';

const SliderContainer = styled.div`
    position: absolute;
    top: 105px;
    right: -20px;
    margin-right: 45px;
    width: 115px;
    height: 35px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    border: 2.133px solid rgba(255, 255, 255, 0.2);

    background: rgba(0, 0, 0);
    transform: rotate(270deg);
    transform-origin: center;
`;

const SliderInput = styled.input`
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    background: linear-gradient(
        to right,
        #beaeff ${(props) => props.value * 100}%,
        #333 ${(props) => props.value * 100}%
    );
    outline: none;
    opacity: 0.7;
    cursor: pointer;

    position: relative;
    z-index: 2;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 20px;
        background: url(${Hex});
        background-size: cover;
        background-position: center;
        border: none;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        background: url(${Hex});
        background-size: cover;
        background-position: center;
        border: none;
        cursor: pointer;
    }

    &::-ms-thumb {
        background: url(${Hex});
        background-size: cover;
        background-position: center;
        border: none;
        border-radius: 50%;
        cursor: pointer;
    }
`;

const VolumeSlider = ({ volume, setVolume }) => {
    const handleChange = (e) => {
        const value = parseFloat(e.target.value);
        setVolume(value);
    };

    return (
        <SliderContainer>
            <SliderInput
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleChange}
                style={{
                    background: `linear-gradient(
                        to right,
                        #BEAEFF ${volume * 100}%,
                        #333 ${volume * 100}%
                    )`,
                }}
            />
        </SliderContainer>
    );
};

export default VolumeSlider;
