import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setColor } from '../../../assets/styles/styles';
import { hexToRGBA } from '../../../utils/generalUtils';
import BackgroundContainer from '../../../components/common/Backgrounds/BackgroundContainer';

const MainContainer = ({ children }) => <Container>{children}</Container>;

MainContainer.propTypes = {
    children: PropTypes.node,
};

export default MainContainer;

const Container = styled(BackgroundContainer)`
    width: 100%;
    max-width: 1280px;
    height: 100%;
    background-color: ${setColor.inputBgColor};
    padding: 0 2rem 0 2rem;
    overflow-x: auto;
    overflow-y: auto;
    border-left: 1px solid ${hexToRGBA(setColor.accent4, 0.1)};
    border-right: 1px solid ${hexToRGBA(setColor.accent4, 0.1)};
    @media (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 0.75em; /* Adjust the width as needed */
            border-width: 1px;
        }

        &::-webkit-scrollbar-track {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
            border-width: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${hexToRGBA(setColor.accent4, 0.1)};
            border-radius: 10px; /* Adjust the border-radius as needed */
            border-width: 1px;
        }
    }

    @media (max-width: 768px) {
        padding: 0 1rem 5rem 1rem; // To account for the bottom navigation bar. Remove large bottom padding if not needed.
    }
`;
