import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000',
        color: '#fff',
        border: '1px solid rgba(255, 255, 255, 0.20)',
        padding: '15px',
        boxShadow: '0px 25px 35px 0px rgba(255, 255, 255, 0.05)',
        fontSize: 11,
        zIndex: 99999,
        maxWidth: 200
    },
}));
