import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import IntraverseLogoIcon from './IntraverseHomeImages/IntraverseLogoIcon.png';
import MenuIcon from './IntraverseHomeImages/HamburgerIconSVG.svg';
import IntraverseLogoFull from './IntraverseHomeImages/IntraverseLogoFull.png';
import DiamondBackground from '../../../assets/images/backgrounds/Diamond_Checkered_Background.png';
import CloseMenu from './IntraverseHomeImages/CloseMenu.png';
import { getWindowSize } from '../../../utils/generalUtils';
import { setColor, setFont } from '../../../assets/styles/styles';

const LandingHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const navigate = useNavigate();
    const routeChange = (path) => {
        navigate(path);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <HeaderContainer>
            {windowSize.innerWidth > 769 && (
                <>
                    <Menu isOpen={isOpen}>
                        <Logo src={IntraverseLogoIcon} alt="Intraverse Logo" />
                        {/* <MenuItems>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="https://terrazero.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <MenuItem>About</MenuItem>
                            </a>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="https://terrazero.com/clients"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <MenuItem>Activations</MenuItem>
                            </a>
                            <MenuItem>Subscribe</MenuItem>
                        </MenuItems> */}
                    </Menu>
                    <MenuButtons>
                        <SignInButton onClick={() => routeChange('/login')}>Sign In</SignInButton>
                        <PlayForFreeButton onClick={() => routeChange('/register')}>Play For Free</PlayForFreeButton>
                    </MenuButtons>
                </>
            )}
            {windowSize.innerWidth < 769 && (
                <MobileHeader aspectRatio={windowSize.aspectRatio}>
                    {!isOpen && (
                        <>
                            <MobileInnerLogo src={IntraverseLogoIcon} alt="Intraverse Logo" />
                            <MenuIconContainer onClick={toggleMenu} src={MenuIcon} alt="Menu Icon" />
                        </>
                    )}
                </MobileHeader>
            )}
            {isOpen && (
                <MobileMenu aspectRatio={windowSize.aspectRatio}>
                    <MobileLogo src={IntraverseLogoFull} alt="Intraverse Full Logo" />
                    <CloseButton onClick={toggleMenu} src={CloseMenu} alt="Close Menu" />
                    {/* <MobileMenuItems aspectRatio={windowSize.aspectRatio}>
                        <MobileMenuItem aspectRatio={windowSize.aspectRatio} style={{ marginTop: '0.5rem' }}>
                            About
                        </MobileMenuItem>
                        <MobileMenuItem aspectRatio={windowSize.aspectRatio}>Activations</MobileMenuItem>
                        <MobileMenuItem aspectRatio={windowSize.aspectRatio}>Subscribe</MobileMenuItem>
                    </MobileMenuItems> */}
                    <MobileMenuButtons>
                        <MobileSignInButton onClick={() => routeChange('/login')}>Sign In</MobileSignInButton>
                        <MobilePlayForFreeButton onClick={() => routeChange('/register')}>Play For Free</MobilePlayForFreeButton>
                    </MobileMenuButtons>
                </MobileMenu>
            )}
        </HeaderContainer>
    );
};

export default LandingHeader;

const HeaderContainer = styled.div`
    position: fixed;
    top: 2.6%;
    left: 0;
    right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: none;
    z-index: 1000;
    padding: 15px 40px;

    @media (max-width: 768px) {
        padding: 15px 25px;
        right: 0.5rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 15px 25px;
        right: 0.5rem;
    }
`;

const Logo = styled.img`
    max-width: 100px;
    margin-right: 3rem;
    margin-left: 1rem;

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 80px;
        margin-right: 2rem;
        margin-left: 0rem;
    }
`;
const Menu = styled.div`
    display: flex;
    align-items: center;
`;

const MenuItems = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
`;

const MenuItem = styled.div`
    color: ${setColor.inputText};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-items: center;
    margin-right: 35px;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 25px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 25px;
    }
`;

const MenuButtons = styled.div`
    display: flex;
    align-items: center;
`;

const SignInButton = styled.button`
    border-radius: 5px;
    border: 2px solid ${setColor.accent4};
    background: ${setColor.purpleBackground};
    padding: 15px 35px;
    margin-right: 20px;
    cursor: pointer;

    color: ${setColor.accent4};
    font-family: ${setFont.barlow};
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (max-width: 768px) {
        padding: 15px 20px;
        font-size: 0.6rem;
        letter-spacing: 1px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 15px 20px;
        font-size: 0.6rem;
        letter-spacing: 1px;
    }
`;

const PlayForFreeButton = styled.button`
    border-radius: 5px;
    background: ${setColor.pinkButtonBgGradient};
    padding: 16px 35px;
    border: none;
    cursor: pointer;

    color: ${setColor.inputText};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (max-width: 768px) {
        padding: 15px 15px;
        font-size: 0.6rem;
        letter-spacing: 1px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 15px 15px;
        font-size: 0.6rem;
        letter-spacing: 1px;
    }
`;

// MOBILE MENU

const MenuIconContainer = styled.img`
    width: 50px;
    height: 50px;
    z-index: 1000;

    position: fixed;
    top: 2rem;
    right: 1.5rem;
    display: flex;

    @media (max-width: 768px) {
        height: 35px;
        width: 35px;
        top: 1.5rem;
        right: 1rem;
    }
`;
const MobileInnerLogo = styled.img`
    width: 50px;
    height: 50px;
    z-index: 1000;
    position: fixed;
    top: 2rem;
    left: 1.5rem;
    display: flex;

    @media (max-width: 768px) {
        height: 35px;
        width: 35px;
        top: 1.5rem;
        right: 0.5rem;
    }
`;

const MobileMenu = styled.div`
    display: flex;
    position: absolute;
    top: -0.6rem;
    left: 1.5%;
    align-items: center;
    flex-direction: column;
    /* flex-direction: ${(props) => (props.aspectRatio === '16:9' ? 'column' : 'row')}; */
    width: 97vw;
    height: fit-content;
    padding-bottom: 1.5rem;
    background: ${setColor.darkBlue};
    background-image: url(${DiamondBackground});
    background-size: 350px auto;
    z-index: 2000;
`;

const MobileHeader = styled.div`
    display: flex;
    align-items: center;
`;
const CloseButton = styled.img`
    width: 60px;
    height: 60px;
    z-index: 1000;

    position: fixed;
    top: 3.7%;
    right: 2rem;
    display: flex;

    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
        right: 1rem;
    }
    @media (max-height: 600px) {
        width: 30px;
        height: 30px;
    }
`;
const MobileLogo = styled.img`
    position: relative;
    top: -4.5rem;
    left: -7rem;
    width: 50vw;
    margin-top: 6rem;

    @media (max-width: 600px) {
        top: -3.7rem;
        left: -3rem;
        width: 50vw;
    }

    @media (max-height: 768px) and (min-width: 600px) {
        width: 30%;
        left: -14rem;
    }
`;

const MobileMenuItems = styled.div`
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
    flex-direction: column;
    width: 80%;

    @media (max-height: 768px) {
        margin-top: -3rem;
    }
`;
const MobileMenuItem = styled.div`
    color: ${setColor.inputText};
    text-align: left;
    font-family: ${setFont.barlow};
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: ${(props) => (props.aspectRatio === '16:9' ? '2.7rem' : '1rem')};

    @media (max-height: 768px) {
        margin-top: -0.2rem;
    }
`;

const MobileMenuButtons = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
`;

const MobileSignInButton = styled.button`
    border-radius: 5px;
    border: 2px solid ${setColor.accent4};
    background: ${setColor.purpleBackground};
    padding: 15px 35px;
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: ${setColor.accent4};
    font-family: 'Barlow', sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (max-height: 768px) {
        margin-top: 0rem;
    }
`;

const MobilePlayForFreeButton = styled.button`
    border-radius: 5px;
    background: ${setColor.pinkButtonBgGradient};
    padding: 15px 35px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 0rem;

    color: ${setColor.inputText};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
`;
