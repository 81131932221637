import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { setColor } from '../../../../assets/styles/styles';
import Button from '../../../../components/common/button/Button';
import Typography from '../../../../components/common/text/Typography';
import Dropdown from '../../../../components/common/inputDropdown/Dropdown';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import Card from './components/Card';
import Modal from '../../components/Modal';
import Banner from '../../../../components/layout/ui/Banner';
import { getAllNotes, updateNote } from '../../../../features/guestbook/api/notesController';
import { CACHE_QUERY_OPTIONS } from '../../../../constants';

const sortByDate = [
    {
        value: 'Latest',
    },
    {
        value: 'Oldest',
    },
];
const sortByStatus = [
    {
        value: 'Published',
    },
    {
        value: 'Pending',
    },
];

const GuestBook = () => {
    const { id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [notes, setNotes] = useState(null);
    const [selectedNotes, setSelectedNoted] = useState([]);
    const [loading, setloading] = useState(true);
    const [orderFilter, setOrderFilter] = useState('Latest');
    const [statusFilter, setStatusFilter] = useState('Pending');
    const [openBanner, setOpenBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerType, setBannerType] = useState('SUCCESS');

    const {
        data: notesData,
        loading: loadingData,
        refetch: refecthNotes,
    } = useQuery(['guestBookNotes', id], () => getAllNotes(id), {
        enabled: !!id,
        ...CACHE_QUERY_OPTIONS(),
    });

    const updateNotes = async () => {
        const length = selectedNotes?.length;
        const result = await updateNote(selectedNotes, id, 'approve');

        if (result.status === 200) {
            refecthNotes();
            setBannerMessage(
                `You published ${length} notes to your guestbook. You can remove them whenever you'd like.`
            );
        } else {
            setBannerType('ERROR');
            setBannerMessage(`There was an issue publishing the notes. Please try again`);
        }

        setOpenBanner(true);
        setSelectedNoted([]);
    };

    const unApproveNote = async (noteId) => {
        const result = await updateNote([noteId], id, 'refuse');
        if (result.status === 200) {
            refecthNotes();
            setBannerType('SUCCESS');
            setBannerMessage('You removed 1 note from your guestbook. Visit your pending notes to republish.');
        } else {
            setBannerType('ERROR');
            setBannerMessage(`There was an issue un-publishing the notes. Please try again`);
        }

        setOpenBanner(true);
        return true;
    };

    const PublishModalContent = ({ updateDeleteModelState }) => (
        <>
            <Typography variant="h2PurpleHeading" margin={'0 0 2rem 0'}>
                Publish <span style={{ color: `${setColor.spanTextColorThree}` }}>{selectedNotes?.length}</span> Notes?
            </Typography>
            <Typography variant="pWhite" margin={'0rem'}>
                You are about to publish {selectedNotes?.length} notes to your guestbook. These will be visible to all
                visitors in your space.
            </Typography>

            <Button
                variant={'PinkButton'}
                buttonWidth={'100%'}
                label={'Publish'}
                margin={'2rem 0 2rem 0'}
                onClick={() => {
                    updateNotes();
                    updateDeleteModelState();
                }}
            />

            <Typography variant="GreyText" onClick={updateDeleteModelState} margin={'0'}>
                GO BACK
            </Typography>
        </>
    );

    const updateSelectedNotes = (id) => {
        if (!selectedNotes.find((note) => note === id)) {
            setSelectedNoted((prev) => [...prev, id]);
        } else {
            setSelectedNoted((prev) => prev.filter((note) => note !== id));
        }
    };

    const filterAndSortNotes = (data, statusFilter, orderFilter) => {
        const filteredNotes = data.filter((note) =>
            statusFilter === 'Published' ? note.isApproved === true : note.isApproved === false
        );

        return filteredNotes.sort((a, b) => {
            if (orderFilter === 'Latest') {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }

            if (orderFilter === 'Oldest') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            }
            return 0;
        });
    };

    useEffect(() => {
        if (notesData) {
            setloading(true);
            setNotes(filterAndSortNotes(notesData, statusFilter, orderFilter));
            setloading(false);
        }
    }, [statusFilter, orderFilter, notesData]);

    const updateDeleteModelState = () => {
        setOpenModal((prev) => !prev);
    };

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={bannerMessage}
                type={bannerType}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                left={'0'}
                timer={6000}
            />
            <Modal
                width={'550px'}
                isOpen={openModal}
                setIsOpen={updateDeleteModelState}
                children={<PublishModalContent updateDeleteModelState={updateDeleteModelState} />}
            />
            <Title
                title={'GuestBook'}
                description={'Click a card to review and publish notes left by your visitors.'}
                button={
                    <Button
                        buttonWidth="100%"
                        variant={'PinkButton'}
                        label={`PUBLISH ${selectedNotes?.length}`}
                        onClick={updateDeleteModelState}       
                        disabled={selectedNotes?.length === 0}
                    />
                }
                navigateBack={notes?.length > 0}
            />
            <FilterContainer>
                <DropDownContainerFlex>
                    <DropdownContainer>
                        <Dropdown
                            key={'sortByStatus'}
                            items={sortByStatus}
                            selectedValue={statusFilter}
                            setSelectedValue={setStatusFilter}
                            placeholder=""
                        />
                    </DropdownContainer>
                    <DropdownContainer>
                        <Dropdown
                            key={'sortByDate'}
                            items={sortByDate}
                            selectedValue={orderFilter}
                            setSelectedValue={setOrderFilter}
                            placeholder=""
                        />
                    </DropdownContainer>
                </DropDownContainerFlex>

                <div>
                    <Typography variant="ConsoleDescription">Total Notes: <strong>{notes?.length}</strong></Typography>
                </div>
            </FilterContainer>
            <Body isLoading={loading || loadingData}>
                {(!notes || notes.length === 0 ) && statusFilter === "Pending" ? (
                    <>
                        <EmptyMessageComponent>
                            <Typography variant="h2PurpleHeading" margin={'0'}>
                                All Caught Up!
                            </Typography>
                            <Typography variant="pWhite">
                                No pending notes to review. Submitted notes will appear here for your review and publishing.
                            </Typography>
                        </EmptyMessageComponent>
                    </>
                ) : (
                    <CardsContainer>
                        {notes.map((note) => (
                            <div
                                key={note.noteId}
                                onClick={() => {
                                    if (!note.isApproved) updateSelectedNotes(note.noteId);
                                }}
                            >
                                <Card
                                    unApproveNote={unApproveNote}
                                    data={note}
                                    key={note.noteId}
                                    selectedCard={selectedNotes.find((noteID) => noteID === note.noteId)}
                                />
                            </div>
                        ))}
                    </CardsContainer>
                )}
            </Body>
        </>
    );
};

export default GuestBook;

const CardsContainer = styled.div`
    margin-top: 25px;
    height: auto;
    width: inherit;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
`;

const DropdownContainer = styled.div`
    width: 140px;
`;

const DropDownContainerFlex = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
`;

const EmptyMessageComponent = styled.div`
    width: 600px;
    justify-content: space-between;
    height: auto;
    border-radius: 20px;
    background: ${setColor.passwordValidBox};
    padding: 2rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 768px) {
        padding: 1rem;
        width: 100%;
    }
`;
