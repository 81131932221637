import React, { useState } from 'react';
import styled from 'styled-components';
import { setColor } from '../../../../../assets/styles/styles';
import Typography from '../../../../../components/common/text/Typography';
import NoSticky from '../../../../../features/guestbook/images/emojis/NoSticky.png';
import Button from '../../../../../components/common/button/Button';
import Modal from '../../../components/Modal';
import { emojiMap } from '../../../../../constants';

const Card = ({ data, selectedCard, unApproveNote }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateDeleteModelState = () => {
        setOpenDeleteModal((prev) => !prev);
    };

    const updateNote = async () => {
        setLoading(true);
        await unApproveNote(data.noteId);
        setLoading(false);
        setOpenDeleteModal(false);
    };

    const DeleteModalContent = ({ updateDeleteModelState }) => (
        <>
            <Typography variant="h2PurpleHeading" margin={'0 0 2rem 0'}>
                Remove Note?
            </Typography>
            <Typography variant="pWhite" margin={'0rem'}>
                You are about to remove this note from your guestbook, which will make it no longer visible to visitors.
                Are you sure you want to proceed?
            </Typography>

            <Button
                variant={'PinkButton'}
                buttonWidth={'100%'}
                label={'Remove'}
                margin={'2rem 0 2rem 0'}
                onClick={updateNote}
                loading={loading}
            />

            <Typography variant="GreyText" onClick={updateDeleteModelState} margin={'0'}>
                GO BACK
            </Typography>
        </>
    );

    return (
        <>
            <Modal
                width={'550px'}
                isOpen={openDeleteModal}
                setIsOpen={updateDeleteModelState}
                children={<DeleteModalContent updateDeleteModelState={updateDeleteModelState} />}
            />

            <CardContainer selectedCard={selectedCard} isApproved={data.isApproved}>
                <StatusTag isApproved={data.isApproved}>{data.isApproved ? 'Published' : 'Pending'}</StatusTag>
                <TitleAndStickyContainer>
                    <div>
                        <img
                            src={emojiMap[data.stickerId] || NoSticky}
                            alt="Sticky"
                            style={{
                                width: '51px',
                                height: '51px',
                            }}
                        />
                    </div>

                    <div>
                        <Typography variant="pWhite" style={{ fontWeight: 800, margin: 0, textAlign: 'left' }}>
                            {data.isAnonymous ? "Anonymous" : data.displayName}
                        </Typography>
                        <Typography
                            variant="ConsoleDescription"
                            style={{ fontWeight: 300, fontSize: '12px', marginTop: '5px' }}
                        >
                            {new Date(data.createdAt).toLocaleDateString()}
                        </Typography>
                    </div>
                </TitleAndStickyContainer>
                <NoteContainer>
                    <Typography variant="ConsoleDescription">{data.message}</Typography>
                </NoteContainer>
                {data.isApproved && (
                    <Button
                        buttonWidth="100%"
                        variant={'SmallButtonGrey'}
                        label="Remove"
                        onClick={() => {
                            setOpenDeleteModal((prev) => !prev);
                        }}
                    />
                )}
            </CardContainer>
        </>
    );
};

export default Card;

const NoteContainer = styled.div`
    flex: 1;
`;

const StatusTag = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${(props) => (props.isApproved ? setColor.darkGreen : setColor.loadingButtonBg)};
    color: ${setColor.circularProgress};
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-bottom-left-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const TitleAndStickyContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const CardContainer = styled.div`
    flex: 1;
    cursor: ${(props) => (props.isApproved ? '' : 'pointer')};
    overflow: hidden;
    position: relative;
    width: 280px;
    height: 100%;
    min-height: 280px;
    border-radius: 10px;
    background: ${setColor.darkPurpleBackground};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    gap: 10px;

    box-shadow: ${(props) =>
        props.selectedCard ? '0 0 0 2px #F0153F, 0 0 0 3px #DD42CE' : '0 0 0 2px rgba(190, 174, 255, 0.15)'};
    @media (max-width: 768px) {
        width: 100%;
    }
`;
