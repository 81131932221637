import React from 'react';
import styled from 'styled-components';
import { GUESTBOOK_FONTS } from '../../constants';

const FontSelection = ({ selectedFont, onSelectFont }) => {

    return (
        <FontSelectionContainer>
            {GUESTBOOK_FONTS.map((font, index) => (
                <FontOption key={index} onClick={() => onSelectFont(font.family)}>
                    <FontBox isSelected={selectedFont === font.family}>
                        <p style={{ fontFamily: font.family, fontSize: '44px' }}>Aa</p>
                        <p style={{ fontFamily: font.family, color: '#9DA0A7', fontSize: '14px', fontWeight: 'normal' }}>{font.name}</p>
                    </FontBox>
                </FontOption>
            ))}
        </FontSelectionContainer>
    );
};

export default FontSelection;

const FontSelectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;

    @media (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

const FontOption = styled.div`
    cursor: pointer;
    padding: 5px;
`;
const FontBox = styled.div`
    cursor: pointer;
    width: 150px;
    height: 150px;
    border-radius: 4px;
    border: ${({ isSelected }) => (isSelected ? '2px solid #f0153f' : 'none')};
    background: #23173e;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & p {
        font-size: 44px;
        color: white;
    }
`;
