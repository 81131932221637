import React from 'react';
import styled from 'styled-components';
import Button from '../../components/common/button/Button';
import TopStars from './images/TopStars.png';
import Typography from '../../components/common/text/Typography';
import ModalCloseIcon from './images/ModalCloseIcon.png';

const EmailModal = ({ onClose, onSelectOption }) => (
    <>
        <Overlay onClick={onClose} />
        <ModalContainer>
            <ModalCloseIconBox onClick={onClose} src={ModalCloseIcon} alt="ModalCloseIcon" />
            <ModalContent>
                <Typography style={{ margin: '2rem' }} variant={'guestbookHeader'} color={'#E4DEFF'}>
                    Guestbook Options
                </Typography>
                <div>
                    <Button
                        label="Leave a Note in Guestbook"
                        variant="PurpleArrowButton"
                        onClick={() => onSelectOption(false)}
                        buttonWidth="100%"
                        margin={'1rem 0'}
                        iconRight={'fluent:chevron-right-12-filled'}
                        iconSize={'40px'}
                        iconColor="#BEAEFF"
                    />
                    <Button
                        label="Email Creator Directly"
                        variant="PurpleArrowButton"
                        onClick={() => onSelectOption(true)}
                        buttonWidth="100%"
                        margin={'1rem 0'}
                        iconSize={'40px'}
                        iconColor="#BEAEFF"
                        iconRight={'fluent:chevron-right-12-filled'}
                    />
                </div>
            </ModalContent>
        </ModalContainer>
    </>
);

export default EmailModal;

const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: flex-start;
    width: 750px;
    height: fit-content;
    background-color: #0e0517;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${TopStars});
    background-size: 100% 50%;
    background-position: top center;
    background-repeat: no-repeat;
    border: 4px solid #3d364f;
    position: fixed;
    top: 25%;
    z-index: 1000;

    @media (max-width: 768px) {
        width: 80%;
    }
`;

const ModalContent = styled.div`
    color: white;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    padding: 2rem;

    @media (max-width: 700px) {
        width: auto;
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;

const ModalCloseIconBox = styled.img`
    position: absolute;
    top: -30px;
    right: -45px;
    height: 100px;
    width: 90px;
    cursor: pointer;
`;
