/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { transferPlayback, handleSpotifyAuth, setRepeatV2, shufflePlayer } from '../api/SpotifyController';
import { getFromSessionStorage } from '../../../hooks/useSessionStorage';
import AlbumPhoto from '../../../assets/images/spotify/noAlbumImage.png';
import UI_Logo_Spotify_Full from '../assets/UI_Logo_Spotify_Full.png';
import minimize from '../assets/icons/minimize-rounded.png';
import Volume from '../assets/icons/Volume.svg';
import Prev from '../assets/icons/Prev.svg';
import Next from '../assets/icons/Next.svg';
import Play from '../assets/icons/Play.svg';
import Pause from '../assets/icons/Pause.svg';
import Shuffle from '../assets/icons/Shuffle.png';
import Loop from '../assets/icons/Loop.png';
import VolumeSlider from './VolumeSlider';
import MusicPlayerProgressBar from './MusicPlayerProgressBar';
import Loop_Active from '../assets/icons/Loop_Active.png';
import Shuffle_Active from '../assets/icons/Shuffle_Active.png';
import Hex from '../assets/icons/Hex.png';
import { getWindowSize, getDeviceOrientation } from '../../../utils/generalUtils';
import { setColor } from '../../../assets/styles/styles';

const track = {
    uri: '',
    name: '',
    album: {
        images: [{ url: '' }],
        name: '',
    },
    artists: [{ name: '' }],
};

function SpotifyControls(props) {
    const spotifyToken = getFromSessionStorage('spotifyAccessToken');
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [deviceId, setDeviceId] = useState(null);
    const [spotifyDisconnected, setSpotifyDisconnected] = useState(false);
    const [openVolume, setOpenVolume] = useState(false);
    const [shuffleActive, setShuffleActive] = useState(false);
    const [loopActive, setLoopActive] = useState(false);
    const [updateSource, setUpdateSource] = useState(false);
    const [ispaused, setPaused] = useState(false);
    const [currentTrack, setTrack] = useState(track);
    const [volume, setVolume] = useState(0.25);
    const intervalRef = useRef(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [orientation, setOrientation] = useState(getDeviceOrientation());

    useEffect(() => {
        if (props?.player) {
            props.player.addListener('ready', async ({ device_id }) => {
                setDeviceId(device_id);
                await transferPlayback(device_id, spotifyToken);
                setPaused(false);

                if (props?.spotifyTrack) {
                    setTimeout(() => {
                        props.startSpotifyPlayer(props?.spotifyTrack);
                    }, 3000);
                }
            });

            props.player.addListener('player_state_changed', (state) => {
                if (state) {
                    const { position, duration, paused } = state;
                    setCurrentTime(position / 1000); // Convert milliseconds to seconds
                    setDuration(duration / 1000);

                    // Clear and restart the interval for the new track
                    if (intervalRef.current) clearInterval(intervalRef.current);

                    if (state?.context?.uri !== null) {
                        const { current_track } = state.track_window;
                        setTrack(current_track);
                    }

                    if (state?.shuffle && !shuffleActive) {
                        setShuffleActive(true);
                    }

                    if (state?.repeat_mode === 2 && !loopActive) {
                        setLoopActive(true);
                    }

                    if (state.paused && !ispaused) {
                        setPaused(true);
                    } else {
                        setPaused(false);
                        intervalRef.current = setInterval(() => {
                            setCurrentTime((prev) => {
                                const newTime = prev + 1;
                                return newTime > duration ? duration : newTime;
                            });
                        }, 1000);
                    }
                } else {
                    props.setOpenSpotify(false);
                    setUpdateSource(true);
                    setSpotifyDisconnected(true);
                }
            });

            props.player.on('account_error', ({ message }) => {
                // Emitted when the user authenticated does not have a valid Spotify Premium subscription.
                console.error('Failed to validate Spotify account', message);
            });

            props.player.on('authentication_error', ({ message }) => {
                // Emitted when the Spotify.Player fails to instantiate a valid Spotify connection from the access token provided to getOAuthToken.
                console.error('Failed to authenticate', message);
            });

            props.player.addListener('autoplay_failed', () => {
                console.log('Autoplay is not allowed by the browser autoplay rules');
            });

            props.player.connect();
            props.player.activateElement();
        }

        return () => {
            props.player.removeListener('player_state_changed');
            props.player.removeListener('autoplay_failed');
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [props.player, spotifyToken]);

    const handleSpotifyPause = () => {
        // eslint-disable-next-line camelcase
        if (ispaused) {
            props.player.resume().then(() => {
                setPaused(false);
            });
        } else {
            props.player.pause().then(() => {
                setPaused(true);
            });
        }
    };

    const handleSpotifyNext = () => {
        props.player.nextTrack().then(() => {
            // eslint-disable-next-line camelcase
            if (ispaused) {
                setPaused((prev) => !prev);
            }
        });
    };

    const handleSpotifyPrevious = () => {
        props.player.previousTrack().then(() => {
            // eslint-disable-next-line camelcase
            if (ispaused) {
                setPaused((prev) => !prev);
            }
        });
    };

    const handleSpotifyShuffle = async () => {
        const response = await shufflePlayer(spotifyToken, !shuffleActive);
        if (response) {
            setShuffleActive((prev) => !prev);
        }
    };

    const handleSpotifyLoop = async () => {
        const response = await setRepeatV2(spotifyToken, loopActive ? 'off' : 'track');
        if (response) {
            setLoopActive((prev) => !prev);
        }
    };

    useEffect(() => {
        if (props.player) {
            props.player.setVolume(volume).then(() => {
                console.log('Volume updated!');
            });
        }
    }, [volume]);

    const handleReconnect = () => {
        transferPlayback(deviceId, spotifyToken).then(() => {
            props.player.resume();
            props.player.setVolume(0.3);
        });
        setSpotifyDisconnected(false);
        setUpdateSource(false);

        if (props.openSpotify) props.setOpenSpotify(false);
    };

    useEffect(() => {
        async function handleWindowResize() {
            setWindowSize(getWindowSize());
            const orientation = getDeviceOrientation();
            setOrientation(orientation);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            {props.openSpotify && !updateSource && spotifyToken && (
                <SpotifyContainer windowSize={windowSize} orientation={orientation}>
                    <LogoContainer>
                        <SpotifyLogo src={UI_Logo_Spotify_Full} />
                        <IconContainer>
                            <VolumeButton
                                src={Volume}
                                alt="Pause Button"
                                onClick={() => setOpenVolume((prev) => !prev)}
                                openVolume={openVolume}
                            />
                            {openVolume && <VolumeSlider volume={volume} setVolume={setVolume} />}
                            <MinmizeIcon src={minimize} onClick={() => props.setOpenSpotify(!props.openSpotify)} />
                        </IconContainer>
                    </LogoContainer>
                    <TrackContainer>
                        <div>
                            <AlbumImagePlayer
                                src={currentTrack?.album?.images[0]?.url || AlbumPhoto}
                                alt="Album Image"
                            />
                        </div>
                        <TextContainer>
                            <SongTitle>{currentTrack?.name || 'Loading thfis dsdsbdkhsb sdsdfgfgdfdgfgfd'}</SongTitle>
                            <ArtistName>{currentTrack?.artists[0]?.name || 'Loading...'}</ArtistName>
                        </TextContainer>
                    </TrackContainer>
                    <MusicPlayerProgressBar duration={duration} currentTime={currentTime} />
                    <ControlContainer>
                        <FwdButton
                            src={shuffleActive ? Shuffle_Active : Shuffle}
                            alt="Shuffle"
                            onClick={handleSpotifyShuffle}
                        />
                        <FwdButton src={Prev} alt="Previous Button" onClick={handleSpotifyPrevious} />
                        <PauseButton src={ispaused ? Play : Pause} alt="Play Button" onClick={handleSpotifyPause} />
                        <FwdButton src={Next} alt="Next Button" onClick={handleSpotifyNext} />
                        <FwdButton src={loopActive ? Loop_Active : Loop} alt="Loop" onClick={handleSpotifyLoop} />
                        {loopActive && <HexImageRight src={Hex} alt="Hex" />}
                        {shuffleActive && <HexImageLeft src={Hex} alt="Hex" />}
                    </ControlContainer>
                </SpotifyContainer>
            )}
            {props.openSpotify && spotifyToken && spotifyDisconnected && (
                <SpotifyDisconnectContainer onClick={handleReconnect}>
                    <SpotifyLogo src={UI_Logo_Spotify_Full} width="40%" />
                    <DiscModalText>
                        Spotify <strong>disconnected</strong>! Tap here to set Intraverse as your device.
                    </DiscModalText>
                </SpotifyDisconnectContainer>
            )}
            {props.openSpotify && !spotifyToken && (
                <SpotifyDisconnectContainer onClick={() => handleSpotifyAuth(process.env.REACT_APP_WEB_URL)}>
                    <SpotifyLogo src={UI_Logo_Spotify_Full} width="40%" />
                    <DiscModalText>
                        Tap here to connect your <strong>Spotify Premium</strong>!
                    </DiscModalText>
                </SpotifyDisconnectContainer>
            )}
        </>
    );
}

export default SpotifyControls;

const SpotifyContainer = styled.div`
    /* width: 450px;
    height: 300px; */
    position: fixed;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(14, 5, 23, 0.9);
    border: 4px solid rgba(190, 174, 255, 0.2);
    border-radius: 10px;
    z-index: 800;
    flex-wrap: nowrap;
    overflow: hidden;

    ${(props) => {
        if (props.orientation === 'portrait') {
            return `
        transform: rotate(-90deg);
        height: 250px;
        width: 300px;
        top: 4vw;
        right: 10vh;
      `;
        }
        return `
            transform: none;
            width: 380px;
            height: 280px;
            top: 120px;
            right: 150px;
          `;
    }}

    @media (max-width: 846px) {
        top: 80px;
        right: 20px;
        height: 220px;
        width: 300px;
    }
`;

const SpotifyDisconnectContainer = styled.div`
    width: 450px;
    height: 300px;
    position: fixed;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(14, 5, 23, 0.9);
    border: 4px solid rgba(190, 174, 255, 0.2);
    border-radius: 10px;
    z-index: 1000;
    top: 150px;
    right: 150px;
    flex-wrap: nowrap; /* Prevent unintended wrapping */
    overflow: hidden; /* Avoid overflow issues */
    cursor: pointer;
`;

const LogoContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TrackContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
`;

const ControlContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`;

const DiscModalText = styled.p`
    color: #ced1d9;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;

    & > strong {
        color: #fbfcff;
        font-weight: 700;
    }
`;

const SpotifyLogo = styled.img`
    height: ${(props) => (props.width ? props.width : '25px')};
    height: ${(props) => (props.width ? 'auto' : '25px')};
`;
const MinmizeIcon = styled.img`
    height: 35px;
    width: 35px;
    margin-left: 10px;
    padding: 1px 1px 8px 1px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
`;
const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const AlbumImagePlayer = styled.img`
    height: 75px;
    margin-right: 25px;
    border-radius: 10px;
    border: 2px solid var(--purple-primary-20, rgba(190, 174, 255, 0.2));
`;

const TextContainer = styled.div`
    flex: 1; /* Allow the container to take up remaining space */
    display: flex;
    flex-direction: column;
    min-width: 0; /* Ensure it works with text-overflow: ellipsis */
`;

const SongTitle = styled.h1`
    overflow: hidden;
    color: #fbfcff;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Barlow', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-edge: cap;
    line-height: 130%;
`;

const ArtistName = styled.p`
    color: #9da0a7;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 5px;
    line-height: 130%;
`;

const HexImageRight = styled.img`
    position: absolute;
    bottom: 18px;
    right: 74px;
    height: 6px;
    cursor: pointer;
`;

const HexImageLeft = styled.img`
    position: absolute;
    bottom: 18px;
    left: 75px;
    height: 6px;
    cursor: pointer;
`;

const FwdButton = styled.img`
    height: 20px;
    cursor: pointer;
`;
const PauseButton = styled.img`
    height: 40px;
    cursor: pointer;
`;

const VolumeButton = styled.img`
    height: 36px;
    cursor: pointer;

    ${(props) => {
        if (props.openVolume) {
            return `
                border: 2.133px solid var(--Purple-Primary, #beaeff);
                border-radius: 5.333px;`;
        }
    }}
`;
