import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import RoomsDetails from './RoomsDetails';
import { setColor } from '../../../../../assets/styles/styles';

const RoomsTable = ({ roomsData, searchInput }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
        navigate(`/console/rooms/${row.id}`);
    };

    const renderRow = (room) => {
        return [room.id, room.name, room.roomType, room.ownerId, room.ownerName, room.createdDateTime];
    };

    return (
        <div>
            {searchInput.length > 0 && roomsData.length !== 0 && (
                <TableWrapper>
                    <DataTable
                        headers={['ROOM ID', 'NAME', 'ROOM TYPE', 'OWNER ID', 'OWNER NAME', 'CREATED AT']}
                        data={roomsData}
                        renderRow={renderRow}
                        openModal={openModal}
                        isModalOpen={isModalOpen}
                        selectedRow={selectedRow}
                        detailsComponent={RoomsDetails}
                    />
                </TableWrapper>
            )}
            {searchInput.length === 0 && (
                <DataTable
                    headers={['ROOM ID', 'NAME', 'ROOM TYPE', 'OWNER ID', 'OWNER NAME', 'CREATED AT']}
                    data={roomsData}
                    renderRow={renderRow}
                    openModal={openModal}
                    isModalOpen={isModalOpen}
                    selectedRow={selectedRow}
                    detailsComponent={RoomsDetails}
                />
            )}
        </div>
    );
};

export default RoomsTable;

const TableWrapper = styled.div`
    width: 100%;
    max-height: 400px; /* Adjust height as necessary */
    overflow-y: auto;
    border-radius: 5px;
    border: 1px solid ${setColor.modalOutline};
    padding: 10px; /* Optional, for spacing inside the box */
`;
